import React from "react"

import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"

import SEO from "Components/core/SEO"
import { Link } from "gatsby"

const Presentations: React.FC<GatsbyProps> = ({ location }) => {
  return (
    <StandardLayout location={location}>
      <SEO
        title="Presentations"
        description="Presentations presented by Liam Myles"
      />
      <h1>Presentations</h1>
      <p>
        Well, it looks like I got around to doing a presentation. So now
        it&apos;s living its best life on my little piece of the web.
      </p>
      <h2>A11y - What, Why, How</h2>
      <p>
        It took me a long time to get up the nerve to create a presentation
        about accessibility. To me, it&apos;s a topic that takes so much energy
        and openness to talk about, that when I fail to help others care about
        it, I can&apos;t help but recoil inwards.
      </p>
      <p>
        Now I don&apos;t know if this presentation will convince anyone, but if
        it helps one person care just a tiny bit more about accessibility, then
        I have achieved my goal.
      </p>
      <p>
        So why don&apos;t you have a look for yourself and see if I did enough.
      </p>
      <Link to="/presentations/a11y">Check out my A11y Presentation</Link>
    </StandardLayout>
  )
}

export default Presentations
